import './Contact.css';
import React, { useState } from 'react';
import axios from 'axios';
import globalConsts from '../../globalConsts';

import { Container, Row } from 'reactstrap';

// Importér Fontawesome Komponenter
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faHome } from '@fortawesome/free-solid-svg-icons';

function Contact() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  // const bodyFormData = new FormData();

  const handleEmail = (event) => {
    setEmail(event.target.value);
    // bodyFormData.set('email', email);
  };
  const handleMessage = (event) => {
    setMessage(event.target.value);
    // bodyFormData.set('message', message);
  };
  const handleSubmit = (event) => {
    console.log(email, message);
    if (email === '' || message === '') {
      alert('Udfyld venligst felterne');
    } else {
      event.preventDefault();
      axios({
        method: 'POST',
        url: `${globalConsts[0]}/server/`,
        data: { email: email, message: message },
      })
        .then((response) => {
          if (response.data.status === 'success') {
            alert('Tak for din besked. Du vil blive besvaret hurtigst muligt.');
            resetForm();
          } else if (response.data.status === 'fail') {
            alert(
              'Der skete desværre en fejl på siden... Venligst kontakt mig direkte på mail eller telefon.'
            );
          } else {
            console.log(response);
          }
        })
        .catch((error) => console.log(error.message));
    }
  };
  const resetForm = () => {
    setEmail('');
    setMessage('');
  };

  return (
    <Container className="contentWrapper">
      <h1 id="h1">Kontakt</h1>
      <h4 className="h4">Du er altid velkommen til at kontakte mig på:</h4>
      <Row>
        <FontAwesomeIcon
          icon={faPhone}
          className="fontAwesomeStylesContact"
        ></FontAwesomeIcon>

        <h4 style={{ marginTop: '2rem' }}>
          <a className="aStyles" href="tel:21379013">
            21 37 90 13
            <span className="tooltiptext">Ring op</span>
          </a>
        </h4>
      </Row>
      <Row>
        <FontAwesomeIcon
          icon={faEnvelope}
          className="fontAwesomeStylesContact mt-3"
        ></FontAwesomeIcon>

        <h4 className="mt-3">
          <a className="aStyles" href="mailto:kontakt@traekunst.dk">
            kontakt@traekunst.dk
            <span className="tooltiptext">Send mail</span>
          </a>
        </h4>
      </Row>
      <Row>
        <FontAwesomeIcon
          icon={faHome}
          className="fontAwesomeStylesContact mt-3"
        ></FontAwesomeIcon>

        <h4 className="mt-3">
          <a
            className="aStyles"
            href="https://www.google.com/maps/place/Skolevej+10,+4930+Maribo/@54.8064622,11.4818966,17z/data=!3m1!4b1!4m5!3m4!1s0x47ad4fae2d5b9653:0x3672fe212414bdc2!8m2!3d54.8064622!4d11.4840853"
            rel="noopener noreferrer"
            target="_blank"
          >
            Skolevej 10 Maribo 4930
            <span className="tooltiptext">Se på kort</span>
          </a>
        </h4>
      </Row>
      <h4 className="mt-5 h4">Eller via nedenstående konkaktform.</h4>
      <form
        className="mt-5"
        id="contact-form"
        onSubmit={handleSubmit}
        method="POST"
      >
        <div className="form-group h4">
          <h4 className="h4" htmlFor="exampleInputEmail1">
            Din mail:
          </h4>
          <input
            type="email"
            className="form-control"
            value={email}
            required
            placeholder="Mail..."
            onChange={handleEmail}
            aria-describedby="emailHelp"
          />
        </div>
        <div className="form-group h4">
          <h4 className="h4" htmlFor="message">
            Skriv en besked:
          </h4>
          <textarea
            className="form-control"
            rows="8"
            placeholder="Besked..."
            value={message}
            required
            onChange={handleMessage}
          ></textarea>
        </div>
        <button type="submit" className="btn submitBtn h4">
          Send
        </button>
      </form>
    </Container>
  );
}

export default Contact;
