import './Frontpage.css';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import globalConsts from '../../globalConsts';
import LazyLoad from 'react-lazyload';

import {
  Container,
  Row,
  Col,
  Card,
  CardImg,
  CardHeader,
  CardBody,
  CardText,
  Button,
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
} from 'reactstrap';

function Frontpage() {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const [highlightedProducts, setHighlightedProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let isMounted = true;
    const fetchWorks = async () => {
      setLoading(true);
      await axios(`${globalConsts[0]}/server/getHighlightedWorks.php`)
        .then((result) => {
          if (isMounted) {
            setHighlightedProducts(result.data);
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };
    fetchWorks();
    return () => {
      isMounted = false;
    };
  }, []);

  const onExiting = () => {
    setAnimating(true);
  };

  const onExited = () => {
    setAnimating(false);
  };

  const next = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === highlightedProducts.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === 0 ? highlightedProducts.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  // Scroll page to top of page
  const scrollTop = () => {
    window.scroll(0, 0);
  };

  return (
    <div className="contentWrapper" id="frontpageContainer">
      <div id="frontDiv">
        <h1>
          Solide unikke træskilte og <br /> kunst i træ
        </h1>
        <p>
          Vi har i mere end 15 år med stor succes og passion lavet træskilte og
          diverse trækunst. <br />
          Her hos os er det træet og kunden i centrum.
        </p>
      </div>
      <Row className="treeRow justify-content-center align-items-center flex-column">
        <Row className="w-100 d-flex justify-content-center mt-5">
          <h1 className="udvalgteProdukterH1">Udvalgte værker</h1>
        </Row>
        <Row className="w-100 ">
          {Array.isArray(highlightedProducts) &&
            highlightedProducts.length > 0 &&
            highlightedProducts.map((product) => {
              return (
                <Col key={product.id} className="treeCol col-12 col-lg-4">
                  <Card className="mb-5 text-center cardFrontpage">
                    <CardHeader className="cardHeader">
                      Billede {product.id}
                    </CardHeader>
                    <CardBody>
                      <LazyLoad height={300} offset={300}>
                        <CardImg
                          width="100%"
                          src={`${globalConsts[0]}/server/workImages/${product.imagePath}`}
                          alt={product.id}
                        />
                      </LazyLoad>
                    </CardBody>
                  </Card>
                </Col>
              );
            })}
          {!loading &&
            Array.isArray(highlightedProducts) &&
            highlightedProducts.length === 0 && (
              <Col className="col-12 mt-5 mb-5 d-flex justify-content-center">
                <h4>
                  Kunne ikke hente udvalgte værker{' '}
                  <span role="img" aria-label="emoji">
                    🙁
                  </span>
                </h4>
              </Col>
            )}
          {loading && (
            <Col className="col-12 mt-5 mb-5 d-flex justify-content-center">
              {/* <Loader type="ThreeDots" height={40} width={60} color="white" /> */}
            </Col>
          )}
        </Row>
        {Array.isArray(highlightedProducts) && highlightedProducts.length > 0 && (
          <Col className="col-12 d-flex justify-content-center">
            <Carousel
              activeIndex={activeIndex}
              next={next}
              previous={previous}
              className="carouselContainer"
            >
              <CarouselIndicators
                items={highlightedProducts}
                activeIndex={activeIndex}
                onClickHandler={goToIndex}
              />
              {Array.isArray(highlightedProducts) &&
                highlightedProducts.map((product) => {
                  return (
                    <CarouselItem
                      onExiting={onExiting}
                      onExited={onExited}
                      key={product.id}
                      className="carouselItem"
                    >
                      <img
                        className="slideShowImage"
                        src={`${globalConsts[0]}/server/workImages/${product.imagePath}`}
                        alt={product.name}
                      />

                      <CarouselCaption
                        captionHeader={product.name}
                        captionText={''}
                      />
                    </CarouselItem>
                  );
                })}
              <CarouselControl
                direction="prev"
                directionText="Previous"
                onClickHandler={previous}
              />
              <CarouselControl
                direction="next"
                directionText="Next"
                onClickHandler={next}
              />
            </Carousel>
          </Col>
        )}
        <Row className="w-100 d-flex justify-content-center mb-3 ">
          <Link to={'/works'} onClick={() => scrollTop()}>
            <Button className="btnSeeMore">Klik her for flere værker</Button>
          </Link>
        </Row>
      </Row>
    </div>
  );
}

export default Frontpage;
