import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../assets/images/traekunstLogoSquare.png';
import './Header.css';

// Importér Reactstrap komponenter
import {
  Container,
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
} from 'reactstrap';

// Importér Fontawesome Komponenter
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons';
import { faUser } from '@fortawesome/free-solid-svg-icons';

function Header() {
  // States med React Hooks
  const [isOpen, setIsOpen] = useState(false);
  const [modal, setModal] = useState(false);

  // Toggles
  const toggle = () => setIsOpen(!isOpen);

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('expiresAt');
    window.location.replace('/');
  };

  useEffect(() => {}, [isOpen]);

  const scrollTop = () => {
    window.scroll(0, 0);
  };

  return (
    <Navbar className="lightBlackBg headerStyles" light expand="md">
      <Container>
        <NavItem className="navBrand">
          <Link to={'/'} className="linkStyles ">
            <img className="iconImg" src={Logo} alt="Forside" />{' '}
            <span className="tooltiptext">Forside</span>
          </Link>
        </NavItem>

        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="ml-auto" navbar>
            <NavItem>
              <Link
                to={'/works'}
                onClick={() => (isOpen ? toggle() : '')}
                className="linkStyles headerItem"
              >
                Mine værker
              </Link>
            </NavItem>
            <NavItem>
              <Link
                to={'/calculator'}
                onClick={() => (isOpen ? toggle() : '')}
                className="linkStyles headerItem"
              >
                Beregn pris
              </Link>
            </NavItem>
            <NavItem>
              <Link
                to={'/contact'}
                onClick={() => (isOpen ? toggle() : '')}
                className="linkStyles headerItem"
              >
                Kontakt
              </Link>
            </NavItem>
            <NavItem>
              <Link
                to={'/faq'}
                onClick={() => (isOpen ? toggle() : '')}
                className="linkStyles headerItem"
              >
                FAQ
              </Link>
            </NavItem>
          </Nav>
          <Nav className="ml-auto" navbar>
            <NavItem>
              <a
                href="https://www.facebook.com/Tr%C3%A6kunst-Tr%C3%A6skilte-1351564048193279/?__tn__=%2Cd%2CP-R&eid=ARCYr9cbG2XBTj8FM2ZGC0b8Tnkr4NV4XPQqbM4SetRrw1FZKwFPNFSwMknuUf_NSoA9xap60dsE_jXS"
                target="_blank"
                rel="noopener noreferrer"
                className="linkStyles"
              >
                {' '}
                <span className="tooltiptext">Facebook</span>
                <FontAwesomeIcon
                  icon={faFacebookSquare}
                  className="fontAwesomeStyles"
                ></FontAwesomeIcon>
              </a>
            </NavItem>
            {!localStorage.getItem('token') && (
              <NavItem>
                <Link
                  to={'/login'}
                  className="linkStyles headerItem userIconLink"
                  onClick={() => (isOpen ? toggle() : '')}
                >
                  <span className="tooltiptext">Login</span>
                  <FontAwesomeIcon
                    icon={faUser}
                    className="fontAwesomeStyles userIcon"
                    style={{ width: '1.7rem' }}
                  ></FontAwesomeIcon>
                </Link>
              </NavItem>
            )}
            {localStorage.getItem('token') && (
              <NavItem>
                <Link
                  to={window.location.pathname}
                  onClick={() => (isOpen ? toggle() : '')}
                  className="linkStyles headerItem logout"
                  onClick={logout}
                >
                  Log ud
                </Link>
              </NavItem>
            )}
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
