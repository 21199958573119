import React, { useState, useEffect } from 'react';
import LazyLoad from 'react-lazyload';
import axios from 'axios';
import globalConsts from '../../globalConsts';

// Importér Reactstrap komponenter
import { Col, Card, CardHeader, CardImg, CardBody } from 'reactstrap';

function Work1() {
  const [works, setWorks] = useState([]);

  useEffect(() => {
    const fetchWorks = async () => {
      const result = await axios(`${globalConsts[0]}/server/getWorks.php`);
      setWorks(result.data);
    };
    fetchWorks();
  }, []);

  var dataReverse = works.slice().reverse();

  return (
    dataReverse
      // .reverse() // Laver reverse på data arrayet så de sidste kommer først
      .slice(0, 30) // Henter de sidste 30
      .map((work) => {
        const { id, imagePath } = work;
        return (
          <Col
            key={id}
            className="col-sm-6 col-md-4 col-lg-4 d-flex align-items-stretch"
          >
            <Card className="mb-5 text-center cardWork">
              <CardHeader className="cardHeader">Billede {id}</CardHeader>
              <CardBody>
                <LazyLoad height={300} offset={300}>
                  <CardImg
                    width="100%"
                    src={`${globalConsts[0]}/server/workImages/${imagePath}`}
                    alt={id}
                  />
                </LazyLoad>
              </CardBody>
            </Card>
          </Col>
        );
      })
  );
}

export default Work1;
