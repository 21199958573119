import React, { useState } from 'react';
import './Login.css';
import axios from 'axios';
import globalConsts from '../../globalConsts';

// Importér Fontawesome Komponenter
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faKey } from '@fortawesome/free-solid-svg-icons';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleEmail = (event) => {
    setEmail(event.target.value);
  };
  const handlePassword = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (email === '' || password === '') {
      alert('Indtast email og password');
    } else {
      axios({
        method: 'POST',
        url: `${globalConsts[0]}/server/login.php`,
        data: { email: email, password: password },
      })
        .then((response) => {
          if (response.data !== '' && response.status === 200) {
            localStorage.setItem('token', response.data.jwt);
            localStorage.setItem('expiresAt', response.data.expireAt);
            // window.location.replace('/add-work?token='+response.data.jwt);
            window.location.replace('/add-work');
          } else {
            console.log(response.status);
            alert('Noget gik galt...');
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            alert('Forkert login');
          }
          if (error.response.status === 400) {
            alert('Beklager, noget gik galt på serveren...');
          }
        });
    }
  };

  return (
    <form
      className="mt-5"
      id="contact-form"
      onSubmit={handleSubmit}
      method="POST"
    >
      <div className="loginWrapper contentWrapper">
        <div className="loginForm">
          <h2 id="loginH2">Login på admin siden</h2>
          <div className="input-group input-group-lg mb-2 mt-3 inputStyle">
            <div className="input-group-prepend">
              <span className="input-group-text iconStyle">
                {' '}
                <FontAwesomeIcon
                  icon={faEnvelope}
                  className="fontAwesomeStylesLogin"
                ></FontAwesomeIcon>
              </span>
            </div>
            <input
              type="text"
              className="form-control"
              placeholder="email"
              name="email"
              onChange={handleEmail}
              required
            />
          </div>
          <div className="input-group input-group-lg mb-3 inputStyle">
            <div className="input-group-prepend">
              <span className="input-group-text iconStyle">
                <FontAwesomeIcon
                  icon={faKey}
                  className="fontAwesomeStylesLogin"
                ></FontAwesomeIcon>
              </span>
            </div>
            <input
              type="password"
              className="form-control"
              placeholder="password"
              name="password"
              onChange={handlePassword}
              required
            />
          </div>
          <input className="btn submitBtn mt-3" type="submit" value="Login" />
        </div>
      </div>
    </form>
  );
};

export default Login;
