import React, { useEffect, useState } from 'react';
import axios from 'axios';
import globalConsts from '../src/globalConsts';

import './App.css';

// Importér komponenter til routing
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

// Importér Reactstrap komponenter
import { Container } from 'reactstrap';

// Importér egne komponenter
import Frontpage from './components/frontpage/Frontpage';
import Calculator from './components/calculator/Calculator';
import Header from './components/header/Header';
import Works from './components/works/Works';
import Contact from './components/contact/Contact';
import Login from './components/login/Login';
import NotFound from './components/notFound/NotFound';
import Faq from './components/faq/Faq';
import Footer from './components/footer/Footer';
import PrivacyPolicy from './components/privacyPolicy/PrivacyPolicy';
import TermsAndConditions from './components/termsAndConditions/TermsAndConditions';
// import Offline from './components/offline/Offline';

// Admin komponenter
import AdminHeader from './components/admin/adminHeader/AdminHeader';
import AddWork from './components/admin/addWork/AddWork';
import DeleteWork from './components/admin/deleteWork/DeleteWork';
import EditWorks from './components/admin/editWorks/EditWorks';

function App() {
  const [validToken, setValidToken] = useState(false);
  const [doneFetching, setDoneFetching] = useState(false);
  useEffect(() => {
    // const checkAuth = () => {
    // const query = new URLSearchParams(window.location.search);
    // const urlToken = query.get('token');
    // if(urlToken != null){
    //   token = urlToken;
    // }
    const token = localStorage.getItem('token');
    if (token) {
      const tokenHeader = 'Bearer ' + token;
      const headers = {
        'Content-Type': 'application/json',
        Authorization: JSON.stringify(tokenHeader),
      };
      axios({
        method: 'get',
        url: `${globalConsts[0]}/server/protected.php`,
        headers: headers,
      })
        .then((result) => {
          setValidToken(true);
          setDoneFetching(true);
        })
        .catch((error) => {
          setDoneFetching(true);
          console.log(error);
          localStorage.removeItem('token');
          // window.location.replace('/');
        });
    } else {
      setDoneFetching(true);
    }
  });

  // Definér angivelsen af Auth route
  const Auth = ({ render: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        validToken ? (
          <Component {...props} />
        ) : (
          window.location.replace('/login')
        )
      }
    />
  );
  return (
    <div>
      <Router>
        <Header />
        {validToken && <AdminHeader />}
        <Switch>
          {/* <Route path="/" exact component={Offline} /> */}
          <Route path="/" exact component={Frontpage} />
          <Route path="/calculator" exact component={Calculator} />
          <Route path="/works" exact component={Works} />
          <Route path="/contact" exact component={Contact} />
          <Route path="/login" exact component={Login} />
          <Route path="/faq" exact component={Faq} />
          <Route path="/privacy-policy" exact component={PrivacyPolicy} />
          <Route
            path="/terms-and-conditions"
            exact
            component={TermsAndConditions}
          />
          {doneFetching ? (
            <Auth
              path="/add-work"
              render={() => (
                <Container>
                  <AddWork />
                </Container>
              )}
            />
          ) : (
            <Route>
              <p>Loading...</p>
            </Route>
          )}
          {doneFetching ? (
            <Auth
              path="/delete-work"
              render={() => (
                <Container>
                  <DeleteWork />
                </Container>
              )}
            />
          ) : (
            <Route>
              <p>Loading...</p>
            </Route>
          )}
          {doneFetching ? (
            <Auth
              path="/edit-works"
              render={() => (
                <Container>
                  <EditWorks />
                </Container>
              )}
            />
          ) : (
            <Route>
              <p>Loading...</p>
            </Route>
          )}
          <Route exact component={NotFound} />
        </Switch>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
