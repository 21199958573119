import React, {useState, useEffect} from 'react';
import axios from 'axios';
import { Button } from 'reactstrap';
import globalConsts from '../../globalConsts';

// Importér Fontawesome Komponenter
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';

function Pagination(props) {
  //   const [page, props.selectedPage] = useState(1);

  const pageNumber = props.chosenPage;

  const [works, setWorks] = useState([]);

  useEffect(() => {
    const fetchWorks = async () => {
      const result = await axios(`${globalConsts[0]}/server/getWorks.php`);
      setWorks(result.data);
    };
    fetchWorks();
  }, [])
  // Sæt pageMax til antallet af Work(n).js filer
  const pageMax = Math.ceil(works.length / 30);

  const handleMinus1 = () => {
    props.selectedPage(pageNumber - 1);
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    props.selectedPage(pageNumber - 1);
  };

  const handleMinus2 = () => {
    props.selectedPage(pageNumber - 2);
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  };

  const handlePlus1 = () => {
    props.selectedPage(pageNumber + 1);
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    props.selectedPage(pageNumber + 1);
  };

  const handlePlus2 = () => {
    props.selectedPage(pageNumber + 2);
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  };

  return (
    <React.Fragment>
      <div className="mb-5 text-center">
        {pageNumber > 1 && (
          <Button className="pagBtn" onClick={handleMinus1}>
            {' '}
            <FontAwesomeIcon
              icon={faAngleLeft}
              className="fontAwesomeArrows"
            ></FontAwesomeIcon>
          </Button>
        )}
        {pageNumber > 2 && (
          <Button className="pagBtn" onClick={handleMinus2}>
            {pageNumber - 2}
          </Button>
        )}
        {pageNumber > 1 && (
          <Button className="pagBtn" onClick={handleMinus1}>
            {pageNumber - 1}
          </Button>
        )}
        <Button className="pagBtn activeBtn">{pageNumber}</Button>
        {pageNumber < pageMax && (
          <Button className="pagBtn" onClick={handlePlus1}>
            {pageNumber + 1}
          </Button>
        )}
        {pageNumber < pageMax - 1 && (
          <Button className="pagBtn" onClick={handlePlus2}>
            {pageNumber + 2}
          </Button>
        )}
        {pageNumber < pageMax && (
          <Button className="pagBtn" onClick={handlePlus1}>
            {' '}
            <FontAwesomeIcon
              icon={faAngleRight}
              className="fontAwesomeArrows"
            ></FontAwesomeIcon>
          </Button>
        )}
      </div>
    </React.Fragment>
  );
}

export default Pagination;
