import React, { useState, useEffect } from 'react';
import './EditWorks.css';
import axios from 'axios';
import globalConsts from '../../../globalConsts';
// import Loader from 'react-loader-spinner';
import { Link } from 'react-router-dom';

import { Container, Table, Button, Input, Row } from 'reactstrap';

function EditWorks() {
  const [works, setWorks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showSaveHighlightedBtn, setShowSaveHighlightedBtn] = useState(false);
  const [saveHighlightedLoading, setsaveHighlightedLoading] = useState(false);
  const [highlightedProductsChanged, setHighlightedProductsChanged] = useState(
    []
  );

  useEffect(() => {
    const fetchWorks = async () => {
      setLoading(true);
      const result = await axios(`${globalConsts[0]}/server/getWorks.php`);
      setWorks(result.data.reverse());
      let highlightedProducts = [];
      for (let i = 0; i < result.data.length; i++) {
        if (result.data[i].highlighted === '1') {
          highlightedProducts[result.data[i].id] = '1';
          setHighlightedProductsChanged(highlightedProducts);
        }
      }
      setLoading(false);
    };
    fetchWorks();
  }, []);

  const handleDelete = (id) => {
    const confirmBox = window.confirm(
      'Er du sikker på at du vil slette ID ' + id + '?'
    );
    if (confirmBox === true) {
      const formData = new FormData();
      formData.append('imageId', id);
      axios({
        method: 'POST',
        url: `${globalConsts[0]}/server/deleteWork.php`,
        data: formData,
      })
        .then((response) => {
          setLoading(false);
          if (response.status === 200) {
            document.getElementById('tr' + id).style = 'display:none';
          } else {
            alert('Kunne ikke fjernes...');
          }
        })
        .catch((error) => console.log(error.message));
    }
  };

  const handleHighlightedWorks = (e, work, index) => {
    let isChecked = e.target.checked;

    let products = [...works];
    let product = { ...products[index], highlighted: isChecked ? '1' : '0' };
    products[index] = product;

    let checkedProducts = 0;
    for (let i = 0; i < works.length; i++) {
      if (works[i].highlighted === '1') {
        checkedProducts++;
      }
    }
    if (checkedProducts < 3 || !isChecked) {
      setWorks(products);
      setShowSaveHighlightedBtn(true);

      if (work.id in highlightedProductsChanged) {
        let highlightedProducts = [...highlightedProductsChanged];
        highlightedProducts[work.id] = isChecked ? '1' : '0';
        setHighlightedProductsChanged(highlightedProducts);
      } else {
        let highlightedProducts = [...highlightedProductsChanged];
        highlightedProducts[work.id] = isChecked ? '1' : '0';
        setHighlightedProductsChanged(highlightedProducts);
      }
    }
  };

  const saveHighlightedWorks = (e) => {
    e.preventDefault();
    setTimeout(() => '', 2000);

    for (let i = 0; i < highlightedProductsChanged.length; i++) {
      // If values is 0 or 1
      if (['0', '1'].includes(highlightedProductsChanged[i])) {
        setsaveHighlightedLoading(true);

        const formData = new FormData();
        formData.append('id', i);
        formData.append('highlighted', highlightedProductsChanged[i]);
        axios({
          method: 'POST',
          url: `${globalConsts[0]}/server/updateWork.php`,
          data: formData,
        })
          .then((response) => {
            if (response.data.code === 200) {
              // setShowAlert(true);
              // setTimeout(() => setShowAlert(false), 3000);
            } else {
              alert('Der skete en fejl');
            }
          })
          .catch((error) => console.log(error.message));
      }
    }
    setsaveHighlightedLoading(false);
    setShowSaveHighlightedBtn(false);
  };
  // Filter on products in the table
  const filterProducts = () => {
    let input, filter, table, tr, i, idCell, idTxtValue;

    input = document.getElementById('filterInput');
    filter = input.value.toUpperCase(); // Value in input field
    table = document.getElementById('productList');
    tr = table.getElementsByTagName('tr');

    for (i = 0; i < tr.length; i++) {
      idCell = tr[i].getElementsByTagName('td')[1];
      if (idCell) {
        idTxtValue = idCell.textContent || idCell.innerText;

        if (idTxtValue.toUpperCase().indexOf(filter) > -1) {
          tr[i].style.display = '';
        } else {
          tr[i].style.display = 'none';
        }
      }
    }
  };

  if (!loading) {
    return (
      <Container className="contentWrapper">
        <h1 id='editH1'>Rediger værk</h1>
        <Input
          type="text"
          id="filterInput"
          className="inputStyles mb-3 mt-4"
          onKeyUp={filterProducts}
          placeholder="Search, you may..."
          autoComplete="off"
        />
        <Table bordered striped className="tableStyles" id="productList">
          <thead className="borderColor tableStyles">
            <tr className="borderColor tableStyles">
              <th className="borderColor tableStyles"></th>
              <th className="borderColor tableStyles">ID</th>
              <th className="borderColor tableStyles">Fremhævet</th>
              <th className="borderColor tableStyles hideWorksOnMobile"></th>
            </tr>
          </thead>
          <tbody className="borderColor tableStyles">
            {Array.isArray(works) &&
              works.length > 0 &&
              works.map((work, i) => {
                return (
                  <tr
                    id={'tr' + work.id}
                    key={work.id}
                    className="borderColor tableStyles"
                  >
                    <td className="borderColor tableStyles">
                      <img
                        src={`${globalConsts[0]}/server/workImages/${work.imagePath}`}
                        alt="Billede af værket"
                        width="100%"
                        className="tableImage"
                      />
                    </td>
                    <td className="borderColor tableStyles">{work.id}</td>
                    <td className="borderColor tableStyles ">
                      <Input
                        type="checkbox"
                        className="editWorksCheckbox"
                        checked={work.highlighted === '1' ? true : false}
                        onChange={(event) =>
                          handleHighlightedWorks(event, work, i)
                        }
                      />
                    </td>

                    <td className="borderColor tableStyles hideWorksOnMobile">
                      <Button
                        className="submitBtn deleteBtn"
                        style={{ width: '100%' }}
                        onClick={() => handleDelete(work.id)}
                      >
                        Slet
                      </Button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
        {showSaveHighlightedBtn && (
          <Button
            type="button"
            className="saveHighlightedProducts secondaryBtnStyles"
            onClick={saveHighlightedWorks}
          >
            {!saveHighlightedLoading && 'Gem fremhævede produkter'}
            {/* {saveHighlightedLoading && (
              <Loader type="ThreeDots" height={20} width={30} color="white" />
            )} */}
          </Button>
        )}
      </Container>
    );
  } else {
    return (
      <Container className="contentWrapper">
        <Row className="justify-content-center mt-5">
          {/* <Loader type="ThreeDots" height={40} width={60} color="white" /> */}
        </Row>
      </Container>
    );
  }
}

export default EditWorks;
