import './Works.css';
import React, { useState } from 'react';
import Work1 from './Work1';
import Work2 from './Work2';
import Work3 from './Work3';
import Work4 from './Work4';
import Work5 from './Work5';
import Work6 from './Work6';
import Work7 from './Work7';
import Work8 from './Work8';
import Work9 from './Work9';
import Work10 from './Work10';
import Pagination from './Pagination';

import { Container, Row, CardDeck } from 'reactstrap';

function Works() {
  const [chosenPage, setChosenPage] = useState(1);

  const handlePage = (childData) => {
    setChosenPage(childData);
  };
  return (
    <Container className="contentWrapper">
      <h1 id='myWorksH1'>Mine værker</h1>
      <h4 className="mb-5" id='H4'>
        Her finder du eksempler på nogle af vores ting, som du kan bruge til inspiration. <br />
        Kontakt os endelig hvis du har nogle specielle ønsker - 
        værkerne bliver lavet på bestilling og kan derfor blive lavet, som du ønsker det.
      </h4>

      <Pagination selectedPage={handlePage} chosenPage={chosenPage} />
      <Row className="justify-content-center">
        <CardDeck>
          {chosenPage === 1 && <Work1 />}
          {chosenPage === 2 && <Work2 />}
          {chosenPage === 3 && <Work3 />}
          {chosenPage === 4 && <Work4 />}
          {chosenPage === 5 && <Work5 />}
          {chosenPage === 6 && <Work6 />}
          {chosenPage === 7 && <Work7 />}
          {chosenPage === 8 && <Work8 />}
          {chosenPage === 9 && <Work9 />}
          {chosenPage === 10 && <Work10 />}
        </CardDeck>
      </Row>
      <Pagination selectedPage={handlePage} chosenPage={chosenPage} />
    </Container>
  );
}

export default Works;
