import './TermsAndConditions.css';
import React from 'react';

import { Container, Row, Col } from 'reactstrap';

function TermsAndConditions() {
  return (
    <Container className="contentWrapper">
        <div id='textContainer'>
        <h1 className='otherH1'>Handelsbetingelser hos traekunst.dk</h1>
        <h2 className='h2' className='h2'>Generelle oplysninger</h2>
        <p className='pTag'>
            Skolevej, 10 <br />
            4930 Maribo <br />
            Telefon: 21379013 <br />
            Email: mh@traekunst.dk <br />
        </p>

        <h2 className='h2'>Priser</h2>
        <p className='pTag'>
            Hos traekunst er alle priserne i danske kroner og angivet inkl. moms og afgifter. Vi forbeholder os ret til fra dag til dag at ændre i priserne uden forudgående samtykke. Der tages forbehold for udsolgte varer.
        </p>

        <h2 className='h2'>Betaling</h2>
        <p className='pTag'>
            Traekunst modtager betaling med VISA-Dankort, VISA, VISA Electron, Mastercard, PayPal, MobilePay, Bankoverførsel. Betalingen vil først blive trukket på din konto, når varen afsendes. Alle beløb er i DKK. Danske kroner og incl. moms. Der tages forbehold for prisfejl og udsolgte/udgåede varer.
        </p>

        <h2 className='h2'>Levering</h2>
        <p className='pTag'>
            Traekunst tilstræber at afsende ordre indgået inden kl. 17 samme dag, ordre herefter sendes næstfølgende hverdag. <br />
            Vi sender til hele Danmark. Fragtpriser fra 0-100 kr. Varer vil blive leveret til en nærliggende pakkeshop, der angives ved bestillingen. <br />
            Vi leverer ikke til udlandet og ikke til Færøerne og Grønland. <br />
            Dine varer sendes med Post Nord. <br />
            OBS: Hvis der ikke er plads på udleveringsstedet bliver pakken flyttet til nærmeste udleveringssted, hvilket du får besked om. Opstår der problemer, kontakt os da. <br />
            Der leveres varer alle ugens hverdage.
        </p>

        <h2 className='h2'>Reklamationsret</h2>
        <p className='pTag'>
            Der gives 2 års reklamationsret i henhold til købeloven. Vores reklamationsret gælder for fejl i materiale og/eller fabrikation. Du kan få varen repareret, ombyttet, pengene retur eller afslag i prisen, afhængig af den konkrete situation. Reklamationen gælder ikke fejl eller skader begået ved forkert håndtering af produktet/ydelsen. Du skal reklamere i "rimelig tid" efter du har opdaget manglen/fejlen. Traekunst vil dække returneringsomkostninger i et rimeligt omfang.
        </p>
        <p className='pTag'>
            Ved returnering, reklamationer og benyttelse af fortrydelsesretten sendes til: <br />
            Traekunst.dk <br />
            Skolevej, 10<br />
            4930 Maribo <br />
            Der modtages ikke forsendelser pr. efterkrav.
        </p>

        <h2 className='h2'>Refusion</h2>
        <p className='pTag'>
            Hvis der er tale om refusion, bedes du medsende bankoplysninger i form af regnr og kontonr, så det aftalte beløb kan overføres. Disse oplysninger kan uden risiko oplyses pr. mail eller anden elektronisk form, da det ikke er følsomme oplysninger og kun vil blive anvendt til vores opfyldelse af refusionen.
        </p>

        <h2 className='h2'>Fortrydelsesret</h2>
        <p className='pTag'>Der gives 14 dages fuld returret på varer købt i vores webshop.</p>

        <p className='pTag'>
            Perioden regnes fra den dag; <br />
            - Hvor du modtager ordren. <br />
            - Får den sidste vare i fysisk besiddelse, når det drejer sig om en aftale om flere forskellige varer, bestilt i én ordre, men leveres enkeltvis eller af flere omgange. <br />
            - Får det sidste parti, eller sidste del i fysisk besiddelse, når det drejer sig om aftale af levering af varer der består af flere partier/dele. <br />
            - Den første vare i fysisk besiddelse, når det drejer sig om regelmæssig levering af varer over en bestemt periode. <br />
            Returneringsomkostninger skal du selv afholde. <br />
            Fortrydelse skal anmeldes til os senest 14 efter købet og fra fortrydelsen skal I senest 14 dage efter returnere forsendelsen. Meddelelsen skal gives pr. mail på mh@traekunst.dk.
        </p>
        <p className='pTag'>Du kan ikke fortryde ved blot at nægte modtagelse af varen, uden samtidig at give tydelig meddelelse herom.</p>

        <h3>Varer undtaget fortrydelsesretten</h3>
        <p className='pTag'>
            Følgende varetyper indgår ikke i fortrydelsesretten: <br />
            - Varer, som er fremstillet efter forbrugerens specifikationer eller har fået et tydeligt personligt præg. <br />
            - Forseglede varer, som af sundhedsbeskyttelses- eller hygiejnemæssige årsager ikke er egnet til at blive returneret, og hvor forseglingen er brudt efter leveringen. <br />
            - Varer, der grundet sin art bliver uløseligt blandet sammen med andre ved levering. <br />
            - Varer, hvor plomberingen er brudt. <br />
            - Aftaler indgået på offentlig auktion. <br />
            - Varer, der forringes eller forældes hurtigt.
        </p>

        <h3 className='h3'>Returnering</h3>
        <p className='pTag'>
            Du skal sende din ordre retur uden unødig forsinkelse og senest 14 dage efter, at du har gjort brug af din fortrydelsesret. Du skal afholde de direkte udgifter i forbindelse med returnering. Ved returnering er du ansvarlig for, at varen er pakket ordentligt ind. Du skal vedlægge en kopi af ordrebekræftelsen. Ekspeditionen går hurtigere, hvis du ligeledes udfylder og vedlægger vores Fortrydelsesformular. <br />
            Du bærer risikoen for varen fra tidspunktet for varens levering og til, vi har modtaget den retur. <br />
            Vi modtager ikke pakker sendt pr. efterkrav.
        </p>

        <h3 className='h3'>Varens stand ved returnering</h3>
        <p className='pTag'>
            Du hæfter kun for eventuel forringelse af varens værdi, som skyldes anden håndtering, end hvad der er nødvendigt for at fastslå varens art, egenskaber og den måde, hvorpå den fungerer. Du kan med andre ord prøve varen, som hvis du prøvede den i en fysisk butik.
        </p>
        <p className='pTag'>
            Hvis varen er prøvet udover, det ovenfor beskrevet, betragtes den som brugt. Hvilket betyder, at du ved fortrydelse af købet kun får en del eller intet af købsbeløbet retur, afhængig af varens handelsmæssige værdi på modtagelsestidspunktet - af returneringen. For at modtage hele købsbeløbet retur må du altså afprøve varen uden egentlig at tage den i brug.
        </p>

        <h3 className='h3'>Tilbagebetaling</h3>
        <p className='pTag'>
            Fortryder du dit køb, får du naturligvis det beløb du har indbetalt til os retur.
            I tilfælde af en værdiforringelse, som du hæfter for, fratrækkes denne købs-beløbet.
            Ved anvendelse af fortrydelsesretten, refunderes alle betalinger modtaget fra dig, herunder leveringsomkostninger (undtaget ekstra omkostninger som følge af dit valg af en anden leveringsform end den billigste form for standardlevering, som vi tilbyder), uden unødig forsinkelse og senest 14 dage fra den dato, hvor vi har modtaget meddelelse om din beslutning om at gøre brug af fortrydelsesretten. Tilbagebetaling gennemføres med samme betalingsmiddel, som du benyttede ved den oprindelige transaktion, medmindre du udtrykkeligt har indvilget i noget andet.
            Vi kan tilbageholde beløbsrefunderingen, indtil vi har modtaget varen retur, med mindre du inden da har fremlagt dokumentation for at have returneret den.
        </p>

        <h3 className='h3'>Afhentning i butik</h3>
        <p className='pTag'>
            På vores webshop er det muligt at vælge at afhente vores produkter i vores butik. På denne måde er det muligt at reservere en vare online, for derefter at afhente varen fysisk i vores butik. Da varen afhentes i vores butik, så gælder samme vilkår, som når du handler fysisk i vores butik.
        </p>

        <h2 className='h2'>Persondatapolitik</h2>
        <p className='pTag'>
            For at du kan indgå aftale med os og handle på vores hjemmeside, har vi brug for følgende oplysninger om dig: <br />
            Navn <br />
            Adresse <br />
            Telefonnummer <br />
            E-mailadresse <br />
            Oplysning om hvad du har købt <br />
        </p>
        <p className='pTag'>
            Vi behandler dine personoplysninger med det formål, at kunne levere varen til dig, og for at kunne behandle henvendelser vedrørende dit køb. Behandlingen sker efter reglerne i vores persondatapolitik for traekunst. Heri kan du læse mere om, hvordan dine oplysninger behandles, hvornår de slettes, og hvilke rettigheder du har som registreret.
        </p>

        <h2 className='h2'>Klagemuligheder - oversigt og links:</h2>
        <p className='pTag'>
            Har du en klage over et produkt, købt i vores Webshop, kan der sendes en klage til: <br />
            Konkurrence- og Forbrugerstyrelsens Center for Klageløsning <br />
            Carl Jacobsens Vej 35 <br />
            2500 Valby <br />
            Link: www.forbrug.dk
        </p>
        <p className='pTag'>
            Hvis du er forbruger med bopæl i et andet EU-land, kan du angive din klage i EU Kommissionens online klageplatform.
            Platformen findes her: http://ec.europa.eu/consumers/odr/
            Angiver du en klage her, skal du oplyse vores E-mail adresse: mh@traekunst.dk
        </p>
    </div>
    </Container>
  );
}

export default TermsAndConditions;