import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './AdminHeader.css';

// Importér Reactstrap komponenter
import {
  Container,
  Button,
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
} from 'reactstrap';

// Importér Fontawesome Komponenter
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey } from '@fortawesome/free-solid-svg-icons';

const AdminHeader = () => {
  // States med React Hooks
  const [isOpen, setIsOpen] = useState(false);

  // Toggles
  const toggle = () => setIsOpen(!isOpen);

  return (
    <Navbar className="lightBlackBg headerStyles navbarStyles" dark expand="md">
      <Container>
        <NavItem className="keyIcon">
          <Link to={window.location.pathname} className="linkStyles ">
            <span>
              <FontAwesomeIcon
                icon={faKey}
                className="adminIcon"
              ></FontAwesomeIcon>
            </span>
          </Link>
        </NavItem>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="mr-auto" navbar>
            <NavItem>
              <Link to={'/add-work'} className="linkStyles headerItem">
                Tilføj værk
              </Link>
            </NavItem>
            {/* 
            <NavItem>
              <Link to={'/delete-work'} className="linkStyles headerItem">
                Slet værk
              </Link>
            </NavItem> */}

            <NavItem>
              <Link to={'/edit-works'} className="linkStyles headerItem">
                Værker
              </Link>
            </NavItem>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
};

export default AdminHeader;
