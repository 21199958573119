import React, { useState, useEffect } from 'react';
import './AddWork.css';
import axios from 'axios';
import globalConsts from '../../../globalConsts';

// Importér Reactstrap komponenter
import { Container, Button, Form, FormGroup, Label, Input } from 'reactstrap';

const AddWork = () => {
  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }
    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }
    setSelectedFile(e.target.files[0]);
  };

  const handleSubmit = (event) => {
    if (selectedFile === '') {
      alert('Vælg et billede');
    } else {
      setLoading(true);
      event.preventDefault();
      const formData = new FormData();
      formData.append('file', selectedFile, selectedFile.name);
      axios({
        method: 'POST',
        url: `${globalConsts[0]}/server/addWork.php`,
        data: formData,
      })
        .then((response) => {
          setLoading(false);
          console.log(response);
          if (response.status === 200) {
            alert('Værket blev tilføjet');
          } else {
            alert('Kunne ikke uploades...');
          }
        })
        .catch((error) => console.log(error.message));
    }
  };

  return (
    <Container className="contentWrapper">
      <Form onSubmit={handleSubmit} method="POST">
        <FormGroup>
          <h2 id="addText">Vælg billede</h2>
          <Input
            id="imageFile"
            type="file"
            multiple
            name="file"
            className="fileInput"
            required
            onChange={onSelectFile}
          />
          {selectedFile && <img src={preview} className="imagePreview" />}
        </FormGroup>
        {loading && <p>Loading...</p>}
        <Button type="submit" className="btn submitBtn mb-3 add">
          Tilføj
        </Button>
      </Form>
    </Container>
  );
};
export default AddWork;
