import React from 'react';
import { Link } from 'react-router-dom';
import './NotFound.css';

import { Container } from 'reactstrap';

function NotFound() {
  return (
    <Container className="contentWrapper">
      <h1 className='h1'>Siden findes desværre ikke</h1>
      <h2 className='h2'>Brug menuen oppe i toppen til at komme tilbage</h2>
      <h2 className='h2'>
        Eller klik
        <Link to={'/'} className="ml-3">
          her
        </Link>
        .
      </h2>
    </Container>
  );
}

export default NotFound;
