import React, { useState, useEffect } from 'react';
import './DeleteWork.css';
import axios from 'axios';
import globalConsts from '../../../globalConsts';

import { Container, Form, FormGroup, Input, Button } from 'reactstrap';

const DeleteWork = () => {
  const [imageId, setImageId] = useState('');
  const [loading, setLoading] = useState(false);
  const handleImageId = (event) => {
    setImageId(event.target.value);
  };

  const handleSubmit = (event) => {
    if (imageId === '') {
      alert('Skriv et ID på et billede');
    } else {
      setLoading(true);
      event.preventDefault();
      const formData = new FormData();
      formData.append('imageId', imageId);
      axios({
        method: 'POST',
        url: `${globalConsts[0]}/server/deleteWork.php`,
        data: formData,
      })
        .then((response) => {
          setLoading(false);
          console.log(response);
          if (response.status === 200) {
            setImageId('');
            alert('Værket blev fjernet');
          } else {
            alert('Kunne ikke fjernes...');
          }
        })
        .catch((error) => console.log(error.message));
    }
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit} method="POST">
        <FormGroup>
          <h2 id='deleteText'>Indtast billede ID</h2>
          <Input
            id="imageId"
            type="text"
            name="imageId"
            value={imageId}
            placeholder="ID som skal slettes"
            className=""
            required
            onChange={handleImageId}
          />
        </FormGroup>
        {loading && <p>Loading...</p>}
        <Button type="submit" className="btn submitBtn mb-3 delete">
          Slet
        </Button>
      </Form>
    </Container>
  );
};
export default DeleteWork;
