import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';

// Importér Fontawesome Komponenter
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopyright } from '@fortawesome/free-solid-svg-icons';

function Footer() {
  // Scroll page to top of page
  const scrollTop = () => {
    window.scroll(0, 0);
  };

  return (
    <footer className="footerContainer">
      <FontAwesomeIcon
        icon={faCopyright}
        className="fontAwesomeFooterStyles"
      ></FontAwesomeIcon>{' '}
      <span className="footerText">
        {new Date().getFullYear()} traekunst.dk
      </span>
      <div id="linksContainer">
        <Link
          to={'/terms-and-conditions'}
          onClick={() => scrollTop()}
          className="footerLinks"
        >
          Handelsbetingelser
        </Link>
        <Link
          to={'/privacy-policy'}
          onClick={() => scrollTop()}
          className="footerLinks"
        >
          Privatpolitik
        </Link>
      </div>
    </footer>
  );
}

export default Footer;
