import React from 'react';
import './Faq.css';

import { Container } from 'reactstrap';

// Importér Fontawesome Komponenter
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { faMinus } from '@fortawesome/free-solid-svg-icons';

function Faq() {

  const handleCollapse = (event, id) => {
    event.preventDefault();

    // Anwser content
    var answer = document.getElementsByClassName("content" + id)
    answer[0].classList.toggle("showContent")

    // Icons
    var plusIcon = document.getElementsByClassName("iconPlus" + id)
    var minusIcon = document.getElementsByClassName("iconMinus" + id);

    plusIcon[0].classList.toggle("hideContent");
    minusIcon[0].classList.toggle("hideContent");
  }

  return (
    <Container className="contentWrapper">
      <h1 id="h1" >Frequently asked questions</h1>
      <section className='section'>
        <div id='1' className='collapsible d-flex justify-content-between align-items-center' onClick={(event)=>{handleCollapse(event, 1)}}>
          <h2 className='faqH2'>Fragt og levering</h2>
          <FontAwesomeIcon
            icon={faPlus}
            className="fontAwesomeStylesFaq iconPlus1"
          ></FontAwesomeIcon>
          <FontAwesomeIcon
            icon={faMinus}
            className="fontAwesomeStylesFaq iconMinus1 hideContent"
          ></FontAwesomeIcon>
        </div>
        <p className="content content1">
          Ordren bliver pakket solidt og godt ind og bliver sendt afsted med PostNord. 
          <br />
          Det ligger typisk mellem 0-150 kr alt efter hvor meget ordren vejer at få den leveret. 
          Men ellers er man altid velkommen til at spørge mere specifikt inden man bestiller. 
          <br />
          Du får en sms eller mail med track and trace nummer, samt når din pakke er klar til afhentning. 
          Du har også mulighed for at hente din pakke enten i Maribo.
        </p>
      </section>
      <section>
        <div id='2' className='collapsible d-flex justify-content-between align-items-center' onClick={(event)=>{handleCollapse(event, 2)}}>
          <h2 className='faqH2'>Hvordan foregår betalingen?</h2>
          <FontAwesomeIcon
            icon={faPlus}
            className="fontAwesomeStylesFaq iconPlus2"
          ></FontAwesomeIcon>
          <FontAwesomeIcon
            icon={faMinus}
            className="fontAwesomeStylesFaq iconMinus2 hideContent"
          ></FontAwesomeIcon>
        </div>
        <p className="content content2">Man kan enten betale over MobilePay, bankoverførsel eller kontant ved afhentning.</p>
      </section>
      <section> 
        <div id='3' className='collapsible d-flex justify-content-between align-items-center' onClick={(event)=>{handleCollapse(event, 3)}}>
          <h2 className='faqH2'>Skal produkterne vedligeholdes?</h2>
          <FontAwesomeIcon
            icon={faPlus}
            className="fontAwesomeStylesFaq iconPlus3"
          ></FontAwesomeIcon>
          <FontAwesomeIcon
            icon={faMinus}
            className="fontAwesomeStylesFaq iconMinus3 hideContent"
          ></FontAwesomeIcon>
        </div>
        <p className="content content3">
          Produkterne er af træ og har typisk brug for vedligeholdelse. 
          Herfra får skiltene altid flere lag skibslak, så de holder længe og godt.
          <br />
          Udendørs skiltene kan have godt af at få noget skibslak, hvis man synes det trænger. 
        </p>
      </section>
      <section>
        <div id='4' className='collapsible d-flex justify-content-between align-items-center' onClick={(event)=>{handleCollapse(event, 4)}}>
          <h2 className='faqH2'>Kan jeg komme med inputs til specielle ønsker?</h2>
          <FontAwesomeIcon
            icon={faPlus}
            className="fontAwesomeStylesFaq iconPlus4"
          ></FontAwesomeIcon>
          <FontAwesomeIcon
            icon={faMinus}
            className="fontAwesomeStylesFaq iconMinus4 hideContent"
          ></FontAwesomeIcon>
        </div>
        <p className="content content4">
          Ja! Altid skriv til mig så ser jeg, hvad jeg kan gøre for at imødekomme det bedst muligt. 
          Jeg ønsker altid at hjælpe og nyder at høre om folks specielle ønsker, så tøv ikke. Det kan være alt fra skrifttypen, til motiv osv.
        </p>
      </section>
      <section>
        <div id='5' className='collapsible d-flex justify-content-between align-items-center' onClick={(event)=>{handleCollapse(event, 5)}}>
          <h2 className='faqH2'>Lugter produktet brændt?</h2>
          <FontAwesomeIcon
            icon={faPlus}
            className="fontAwesomeStylesFaq iconPlus5"
          ></FontAwesomeIcon>
          <FontAwesomeIcon
            icon={faMinus}
            className="fontAwesomeStylesFaq iconMinus5 hideContent"
          ></FontAwesomeIcon>
        </div>
        <p className="content content5">
          Nogle af produkterne bliver skåret i en laser og bliver derfor brændt. Derfor kan der forekomme en røglugt. 
          Lad evt. produkterne ligge og få luft, når de har fået lidt luft, vil den brændte lugt foretage sig. 
        </p>
      </section>
      <section className='lastSection'>
        <div id='6' className='collapsible d-flex justify-content-between align-items-center' onClick={(event)=>{handleCollapse(event, 6)}}>
          <h2 className='faqH2'>Kan produkterne tåle at være udenfor?</h2>
          <FontAwesomeIcon
            icon={faPlus}
            className="fontAwesomeStylesFaq iconPlus6"
          ></FontAwesomeIcon>
          <FontAwesomeIcon
            icon={faMinus}
            className="fontAwesomeStylesFaq iconMinus6 hideContent"
          ></FontAwesomeIcon>
        </div>
        <p className="content content6">
          Skriv meget gerne til mig, hvis produktet skal hænge udenfor sådan så jeg kan give træet den rette behandling og dig den bedste vejledning. 
          Hvis produktet ikke i forvejen er lavet til at være ude, kan jeg nemlig desværre ikke garantere holdbarheden.
        </p>
      </section>


    </Container>
  );
}

export default Faq;
